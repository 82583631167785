import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          HeroParty
        </h1>
        <p>
          Unlock the Power of Personalized Data with Zero-Party Insights
        </p>
      </header>
    </div>
  );
}

export default App;
